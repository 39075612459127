<template>
  <v-container fluid>

    <v-row class="mb-2">
      <v-col cols="12" sm="6"></v-col>
      <v-col cols="12" sm="6" class="text-right">
        <v-btn small color="primary" @click="downloadPortfolio()" :disabled="downloading" class="mr-2"><v-icon left small>fa fa-file-excel</v-icon> Portfolio</v-btn>
        <v-btn small color="primary" @click="createInvestment()"><v-icon left small>fa fa-plus</v-icon> {{ $t('actions.create') }}</v-btn>
      </v-col>
    </v-row>

    <v-data-table :headers="headers" :items="investments" :items-per-page="-1" class="elevation-1" :loading="loading" dense>
      <template v-slot:item._actions="{ item }">
        <v-btn @click="viewInvestment(item)" icon small><v-icon small>mdi-eye</v-icon></v-btn>
        <v-btn @click="editInvestment(item)" icon small><v-icon small>mdi-pencil</v-icon></v-btn>
        <v-btn @click="deleteInvestment(item)" icon small><v-icon x-small>fa fa-trash</v-icon></v-btn>
      </template>
      <template v-slot:item.investmentRounds="{ item }">
       {{ sum(item.investmentRounds ) }}
      </template>
      <template v-slot:item.investee.companyActivities="{ item }">
        <span v-for="ca in item.investee.companyActivities" :key="ca.id">
          {{translate(ca.activity.sector.name)}}
        </span>
      </template>
    </v-data-table>

    <!-- edit dialog -->
    <v-dialog v-model="editDialog" max-width="600">
      <InvestmentForm :investment="editedInvestment" v-if="editDialog" @save="onDialogSave" />
    </v-dialog>


    <!-- view dialog -->
    <v-dialog v-model="viewDialog" max-width="400">
      <v-card v-if="investment">

        <v-list-item two-line>
          <v-list-item-content>
            <v-list-item-title class="text-h5">
              {{ investment.investee.name }}
            </v-list-item-title>
            <v-list-item-subtitle>{{investment.type}}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-card-text>

          <h3>Rounds</h3>
          <ul class="transparent">
            <li
                v-for="round in investment.investmentRounds"
                :key="round.id"
            >
              <b>{{ round.invested_at.substr(0, 4) }}</b>
                {{ round.amount }}
            </li>
          </ul>
        </v-card-text>

        <v-card-actions >
          <v-btn text color="primary" @click="viewDialog = false">Close</v-btn>
          <v-btn text color="primary" @click="editInvestment(investment)">Edit</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import Vue from "vue";
import {Component, Prop} from "vue-property-decorator";
import formatter from "@/mixins/formatter";
import InvestmentForm from "@/components/company/InvestmentForm";
import {downloadToDisk} from "@/utils/download";

@Component({
  components: {InvestmentForm},
  mixins: [formatter]})
export default class Investments extends Vue {
  @Prop() company;

  loading = true;
  investments = [];
  investment = null;

  editedInvestment = null;

  editDialog = false;
  viewDialog = false;

  downloading = false;

  get headers() {
    return [
      { text: '', value: '_actions', },
      { text: this.$i18n.t('models.company.self'), value: 'investee.name' },
      { text: this.$i18n.t('models.investmentRound.amount'), value: 'investmentRounds' },
      { text: this.$i18n.t('models.sector.self'), value: 'investee.companyActivities' },
    ];
  }

  getInvestments() {
    this.$api.get(`company/${this.company.id}?expand=investments.investee.companyActivities.activity.sector,investments.fund,investments.investmentRounds`).then(res => {
      this.investments = res.data.investments;
      this.loading = false;
    });

  }

  created() {
    this.getInvestments();
  }

  sum(foo) {
    let total = 0;
    foo.forEach((investmentRound) => total += parseFloat(investmentRound.amount) );
    return total;
  }

  viewInvestment(investment) {
    this.investment = investment;
    this.viewDialog = true;
  }

  editInvestment(investment) {
    this.viewDialog = false;
    this.investment = null;
    this.editedInvestment = investment;
    this.editDialog = true;
  }

  createInvestment() {
    this.editInvestment({
      type: 'mezzanine',
      investor_id: this.company.id,
    })
  }

  onDialogSave() {
    this.getInvestments();
    this.editDialog = false;
    this.editedActivityId = null;
  }

  downloadPortfolio() {
    this.downloading = true;
    this.$api.post(`company/${this.company.id}/download-portfolio`,  {},{
      responseType: 'blob'
    }).then(response => {
      this.downloading = false;
      downloadToDisk(response);
    });
  }

  deleteInvestment(investment) {
    this.$root.$confirm.open('Delete investment', 'Are you sure you want to delete this investment ?', {}).then((confirm) => {
      if (confirm) {
        this.$api.delete(`investment/${investment.id}`).then(() => {
          this.investments.splice(this.investments.indexOf(investment), 1);
        }).catch(() => {
          alert("Unable to remove investment");
        });
      }
    });
  }

}

</script>

