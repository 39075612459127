/**
 * Vuex
 *
 * @library
 *
 * https://vuex.vuejs.org/en/
 */

// Lib imports
import Vue from "vue";
import Vuex from "vuex";
import createMutationsSharer from "vuex-shared-mutations";

// Store functionality
import actions from "./actions";
import getters from "./getters";
import modules from "./modules";
import mutations from "./mutations";
import state from "./state";

Vue.use(Vuex);

const store = new Vuex.Store({
  actions,
  getters,
  modules,
  mutations,
  state,
  plugins: [
    createMutationsSharer({ predicate: ["sudoSuccess", "endSudoSuccess"] }),
  ],
});

export default store;
