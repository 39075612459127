<template>
  <validation-provider
      :name="name"
      :rules="theRules"
      :delay="1000"
      v-slot="validationContext"
  >
    <vue-editor
        v-if="component === 'vue-editor'"
        v-model="v"
        v-bind="$attrs"
        :editorToolbar="editorToolbar"
        class="bg-white"
        @input="$emit('input', v)"
        :error-messages="validationContext.errors[0]"
        :state="getValidationState(validationContext)"
    ></vue-editor>
    <ActivitySelector 
        v-else-if="component === 'ActivitySelector'"
        v-model="v"
        :error="validationContext.errors[0]"
        @input="$emit('input', v)"
     />
    <v-radio-group 
        v-else-if="component === 'v-radio-group'" 
        v-model="v" 
        v-bind="$attrs"
        @change="$emit('input', v)"
        :error-messages="validationContext.errors[0]"
       >
      <slot />
    </v-radio-group>
    <component
        ref="input"
        v-else
        :is="component"
        :label="theLabel"
        v-model="v"
        :required="rules && rules.required"
        @input="$emit('input', v)"
        @change="$emit('input', v)"
        v-bind="$attrs"
        v-on="$listeners"
        :placeholder="placeholder"
        :error-messages="error || validationContext.errors[0]"
    ></component>
    <div v-if="hint" class="hint">{{ hint }}</div>
  </validation-provider>
</template>

<script>
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { VueEditor } from "vue2-editor";
import ActivitySelector from '@/pages/company/ActivitySelector';
import CONFIG from "@/config";

@Component({ components: { VueEditor, ActivitySelector } })
export default class Field extends Vue {
  @Prop() value;
  @Prop() model;
  @Prop({ default: "" }) hint;
  @Prop() rules;
  @Prop() name;
  @Prop() label;
  @Prop() placeholder;
  @Prop() error;
  @Prop({ type: Boolean, default: false }) autovalidate;
  @Prop({ default: "v-text-field" }) component;

  @Watch("value")
  onValueChanged() {
    this.v = this.value;
  }

  v = null;
  editorToolbar = CONFIG.editorToolbar;

  getValidationState({ dirty, validated, valid = null }) {
    return dirty || validated ? valid : null;
  }

  created() {
    this.v = this.value;
  }

  mounted() {
    if (this.autovalidate) {
      this.$refs.input.$el.querySelector('input').dispatchEvent(new Event('input'))
    }
  }

  get theLabel() {
    if (false === this.label) return undefined;
    return (this.label || this.name) + (this?.rules?.required ? ' *' : '');
  }

  get theRules() {
    return Array.isArray(this.rules) ? '' : this.rules;
  }
}
</script>

<style lang="scss">
.hint {
  font-style: italic;
  font-size: 0.8rem;
  margin-left: 0.5em;
  color: #555;
}

.ql-editor {
  font-style: italic;

  p {
    margin-bottom: 0.5em !important;
  }

  h3,
  h4,
  h5 {
    margin-bottom: 0.5em !important;
    font-weight: bold;
  }
}

.v-textarea textarea {
  background-image: url(../assets/notepad.png);
  background-repeat: repeat;
  color: #333;
  line-height: 1.21rem !important;
  padding-top: 0.42em !important;
}
</style>
