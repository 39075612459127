export const forecast = (data: number[], alpha: number, beta: number, size = 3) => {
    const initialCount = data.length;


    const T = [];
    const C = [];

    C[0] = data[0];
    T[0] = data[1] - data[0];

    for (let t = 1; t < data.length; t++) {
        C[t] =
            (alpha * data[t]) +
            ((1 - alpha) *
        (C[t - 1] + T[t - 1]));
        T[t] = (beta * (C[t] - C[t - 1])) + ((1 - beta) * T[t - 1]);

        if (t === data.length - 1) {
            data.push(C[t] + T[t]);
        }

        if (t === size + initialCount - 1) {
            break;
        }
    }

    return data.slice(initialCount, initialCount + size);
}