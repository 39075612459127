







































import {Component, Vue} from "vue-property-decorator";
import formatter from '@/mixins/formatter';
import {AxiosResponse} from "axios";
import {StockExchange} from "@/smartmsi";

@Component({components: {}, mixins: [formatter]})
export default class MsiIndexForm extends Vue {

  newSymbol = "";
  candidates = [];
  composition = [];
  currentIsin: Partial<StockExchange> = {};

  searchingSymbol = false;
  importingSymbol = false;

  status = "";

  findSymbol() {
    this.searchingSymbol = true;
    this.status = "Searching for " + this.newSymbol;
    this.$api.get(`msi-index/find-index?index=${this.newSymbol}`)
        .then((res: AxiosResponse) => {
          if (res.data.length === 1) {
            // Exact match, go ahead
            this.status = `Found ${res.data[0].name}, now fetching composition`;
            this.currentIsin = res.data[0];
            this.findComposition(res.data[0])
          } else {
            // Ask for guidance
            this.status = `Found ${res.data.length} candidates, please select one below`;
            this.candidates = res.data;
          }


        }).catch();
  }

  async importSymbol() {
    this.importingSymbol = true;
    this.status = `Importing ${this.currentIsin.name} ...`;

    const resIndex: AxiosResponse<StockExchange> = await this.$api.post(`msi-index/insert-stock-exchange?since=2015-01-01&uri=${this.currentIsin.uri}`);
    const index = resIndex.data;

    for (let i = 0; i < this.composition.length; i++) {
      this.status = `Importing ${this.composition[i].name} ...`;
      await this.$api.post(`msi-index/insert-stock-exchange?since=2015-12-31&uri=${this.composition[i].url}&index=${index.id}`);
      this.composition[i].imported = true;
    }

    await this.$api.post(`msi-index`, {
      stock_exchange_id: index.id,
    })

    this.$emit('done');
    this.importingSymbol = false;
  }

  findComposition(candidate: Record<string, string>) {
    this.$api.get(`msi-index/fetch-composition?uri=${candidate.uri}`)
        .then((res: AxiosResponse<StockExchange[]>) => {
          this.composition = [];
          res.data.forEach(comp => {
            this.composition.push({imported: false, ...comp});
          })
          this.status = `Found ${this.composition.length} elements in ${candidate.name}`;
        }).catch();
  }

}
