export const fromExcelFormat = (value: number|string, format: string|undefined) => {
    if (null === value) return 'N.C.';
    if (undefined === value) return '';
    value = parseFloat(value.toString());

    // all the formats that contain #,##0.000 are treated the same :/
    if (format && format.match('##0')) {
        return value.toFixed(3).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
    }

    switch (format) {
        case '0%':
            return Math.round(100 * value) + '%';
        case '0.00%':
            return (100 * value).toFixed(2) + '%';
        case '0.00':
            return value.toFixed(2);
        case '00,000':
            return value.toFixed(3);
        case '0.000':
            return value.toFixed(3);
        case '_-* #,##0\\ _€_-;\\-* #,##0\\ _€_-;_-* "-"??\\ _€_-;_-@_-':
            return value.toFixed(3);
        case '_-* #,##0.00\\ _€_-;\\-* #,##0.00\\ _€_-;_-* "-"??\\ _€_-;_-@_-':
            return value.toFixed(2);
        default:
            return value.toString();
    }
}