




















import {Component, Prop, Vue} from "vue-property-decorator";
import Flashable from "@/components/stock/Flashable.vue";
@Component({
  components: {Flashable}
})
export default class StockSamples extends Vue {
  @Prop() samples!: object;
  @Prop() last!: number;
  @Prop({required: false, default: false}) msi !: boolean;
}
