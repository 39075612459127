








import {Component, Prop, Vue} from "vue-property-decorator";
import {CompanyScores} from "@/smartmsi";
import {Chart} from "highcharts-vue";
import Debug from "@/components/Debug.vue";

@Component({components: {Debug, Chart}})
export default class ScoreGauge extends Vue {
  @Prop() scores!: CompanyScores;

  series = [0];

  gaugeOptions = {
    chart: {
      type: 'solidgauge',
    },

    title: null,

    pane: {
      startAngle: -90,
      endAngle: 90,
      background: {
        innerRadius: '60%',
        outerRadius: '100%',
        shape: 'arc'
      }
    },

    exporting: {
      enabled: false
    },

    tooltip: {
      enabled: false
    },

    // the value axis
    yAxis: {
      min: 0,
      max: 200,
      title: {
        text: 'Conformité Globale',
        y: -100
      },
      stops: [
        [0.1, '#DF5353'], // red
        [0.5, '#DDDF0D'], // yellow
        [0.9, '#55BF3B'], // green
      ],
      lineWidth: 0,
      tickWidth: 0,
      minorTickInterval: null,
      tickAmount: 2,
      labels: {
        y: 16
      }
    },

    plotOptions: {
      solidgauge: {
        dataLabels: {
          y: -30,
          borderWidth: 0,
          useHTML: true
        }
      }
    },


    credits: {
      enabled: false
    },

    series: [{
      name: 'Conformité globale MSI',
      data: [],
      dataLabels: {
        format:
            '<div style="text-align:center">' +
            '<span style="font-size:25px">{y:.2f}</span><br/>' +
            '<span style="font-size:12px;opacity:0.4">pts</span>' +
            '</div>'
      },
      tooltip: {
        valueSuffix: ' km/h'
      }
    }]
  };

  created() {
    const lastYear = Math.max(...Object.keys(this.scores));

    this.series[0] = this.scores[lastYear]["global"];

    this.$set(this.gaugeOptions.series[0], 'data', this.series);
  }

}
