
























import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import Field from "@/components/Field.vue";

@Component({
  components: {Field}
})
export default class MField extends Vue {
  @Prop() value!: string;
  @Prop() m!: string;
  @Prop() a!: string;
  @Prop({type: Boolean}) i18n!: boolean;

  v?: string;

  created() {
    this.v = this.value;
  }

  get rules() {
    return this.$store.state.rules;
  }

  get languages() {
    return this.$store.state.availableLanguages;
  }

  @Watch("value")
  onValueChanged() {
    this.v = this.value;
  }

}
