






import {Component, Prop, Vue, Watch} from "vue-property-decorator";

@Component
export default class Flashable extends Vue {
  @Prop() value!: number;
  @Prop({default: ""}) suffix!: string;
  @Prop() format!: string;
  @Prop() decimals!: number;

  style = '';
  timer = 0;

  get finalValue() {
    if (this.format === 'decimal') {
      const factor = Math.pow(10, this.decimals);
      return Math.round(this.value * factor) / factor;
    } else if (this.format === 'currency') {
      return new Intl.NumberFormat('fr-FR', {
        style: 'currency',
        currency: 'EUR'
      }).format(this.value).replace('€', '');
    }

    return this.value;
  }

  @Watch('value')
  onLastChanged(value: number, oldValue: number) {
    window.clearTimeout(this.timer);
    console.log("flash", oldValue, value);
    if (oldValue.toFixed(2) > value.toFixed(2)) {
      this.style = 'animation-name: negative-flash';
    } else if (oldValue.toFixed(2) < value.toFixed(2)) {
      this.style = 'animation-name: positive-flash';
    } else {
      this.style = 'animation-name: none';
    }
    this.timer = window.setTimeout(() => this.style = 'animation-name: none', 600);
  }
}
