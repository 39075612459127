const canUseWebP = (() => {
  const elem = document.createElement("canvas");

  if (elem.getContext && elem.getContext("2d")) {
    // @ts-ignore
    const testString = !(window.mozInnerScreenX == null) ? "png" : "webp";
    // was able or not to get WebP representation
    return (
      elem.toDataURL("image/webp").indexOf("data:image/" + testString) === 0
    );
  }

  return false;
})();

export default {
  methods: {
    cdnImage(src: string, width = null) {
      let cdnSrc = src;
      if (width) {
        cdnSrc = cdnSrc.replace(/(.gif|.jpe?g|.png)/, `.${width}w$1`);
      }
      if (canUseWebP) {
        cdnSrc = cdnSrc.replace(/(.gif|.jpe?g|.png)/, ".webp");
      }
      return cdnSrc;
    },
  },
};
