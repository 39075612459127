<template>
    <div class="editor" ref="editor" @focus="onfocus" @blur="onblur" tabindex="1" @mousedown="caret = code.length">
      <!-- <input type="checkbox" class="code" v-model="showCode" label="Show Code" /> -->
      <span 
        v-for="(f, k) in display" :key="k"
        :ref="`f${k}`"
        v-html="html(f)" 
        :style="style(f)"
        :title="f.error"
        @mousedown.stop="codeMousedown($event, k)"
        :class="cls(f, k)"
        ></span>
      <!-- variables selection -->
      <div class="selector" v-if="showSelector">
        <input 
          v-model="selectionFilter" 
          ref="q" 
          @keydown.esc="quitSelector"
          @keydown="filterKeyHandler"
          >
        <div class="selection-content">
          <table class="selection-list">
            <tr v-for="(v,idx) in selection" :key="idx"
              :value="v.key"
              class="selection-item"
              :class="{ [v.type]: true, selected: selectionIndex === idx  }"
              :ref="`item-${idx}`"
              >
              <td class="type" @click="insert({ v: v.key, type: 'var' })">{{v.code}}</td>
              <td class="name">
                <span @click="insert({ v: v.key, type: 'var' })">{{v.name}}</span>
                <span @click="insert({ v: v.key, type: 'var', year: -1 })">[y-1]</span>
                <span @click="insert({ v: v.key, type: 'var', year: -2 })">[y-2]</span>
                <span @click="insert({ v: v.key, type: 'var', year: -3 })">[y-3]</span>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>
</template>

<script>

import formatter from '@/mixins/formatter';

export default {
    name: 'FormulaEditor',
    mixins: [formatter],
    props: ['value', 'config'],
    data() {
      return {
        v: this.value,
        code: [],
        clipboard: [],
        caret: 0,
        selectionFilter: '',
        showSelector: false,
        vars: [],
        selectionIndex: -1,
        showCode: false,
      }
    },
    created() {
      // build vars index
      const vars = [];
      this.config.financialStatements.forEach(fs => {
        fs.children.forEach(fh => {
          fh.children.forEach(fa => {
            vars.push({ 
              name: this.translateName(fa), 
              code: fa.code,
              type: 'fsa',
              key: `{fsa:${fa.id}}`,
            });
          })
        })
      });
      this.config.aggregateHeadings.forEach(agh => {
        agh.children.forEach(agg => {
          vars.push({ 
            name: this.translateName(agg),
            code: agg.code,
            type: 'agg',
            key: `{agg:${agg.id}}`,
          });
        })
      });
      this.config.axes.forEach(axe => {
        axe.children.forEach(theme => {
          theme.children.forEach(inst => {
            vars.push({ 
              name: this.translateName(inst),
              code: inst.code,
              type: 'inst',
              key: `{inst:${inst.id}}`,
            });
          })
        })
      });
      console.log(vars)
      this.vars = vars;
    },
    mounted() {
      this.$refs['editor'].focus();
      this.caret = this.code.length;
    },
    methods: {
      html(f) {
        switch (f.type) {
          case 'var':
            const v = this.vars.find(v => v.key === f.v);
            let html = this.showCode ? v.code : v.name;
            if (f.year) html += ` (Y${f.year})`;
            return html;
          case 'placeholder':
            return '&nbsp;';
          default:
            return f.v;
        }
      },
      style() {
        return {

        };
      },
      cls(f, k) {
        const st = {
          current: k === this.caret
        }
        if (f.type === 'var') {
          st.var = true;
          if (f.v.match('fsa')) st.fsa = true;
          if (f.v.match('agg')) st.agg = true;
          if (f.v.match('inst')) st.inst = true;
        }
        if (f.error) {
          st.parseerror = true;
        }
        return st;
      },
      onfocus() {
        window.addEventListener('keydown', this.keyHandler);
      },
      onblur() {
        window.removeEventListener('keydown', this.keyHandler);
      },
      keyHandler(e) {
        switch (e.key) {
          case 'ArrowLeft':  this.moveLeft(); return;
          case 'ArrowRight': this.moveRight(); return;
          case 'Home': this.caret = 0; e.preventDefault(); return;
          case 'End': this.caret = this.code.length; e.preventDefault(); return;
          case 'ArrowUp':  e.preventDefault(); return;
          case 'ArrowDown': e.preventDefault(); return;
          case 'Delete': this.delete(); return;
          case 'Backspace': if (this.caret > 0) { this.delete(true); } return;
          case ' ': e.preventDefault(); return;
          case 'Escape': if (this.showSelector) this.showSelector = false; else this.$emit('close'); return;
          case 'Enter': this.emit(); this.$emit('close'); return;
        }

        // clipboard
        if (e.ctrlKey) {
          switch (e.key) {
            case 'x': this.clipboard = this.delete(); return;
            case 'c': this.clipboard = [{...this.code[this.caret]}]; return;
            case 'v': if (this.clipboard.length) { this.insert(this.clipboard[0]); return; }
          }
        }

        // operators & numbers
        if (e.key === '(') {
          this.insert({ v: e.key, type: 'open' });
          return;
        }
        if (e.key === ')') {
          this.insert({ v: e.key, type: 'close' });
          return;
        }
        if (e.key.match(/^[0-9.]$/)) {
          this.insert({ v: e.key, type: 'digit' });
          return;
        }
        if (e.key.match(/^[/*+-]$/)) {
          this.insert({ v: e.key, type: 'operator' });
          return;
        }

        // letter ? launch selector
        if (e.key.match(/^[a-z]$/i)) {
          this.showSelector = true;
          this.selectionFilter = '';
          this.$nextTick(() => {
            this.$refs.q.focus();
          })
        }

        // console.log(e);
      },
      filterKeyHandler(e) {
        switch (e.code) {
          case 'ArrowUp':   
            this.selectionIndex = Math.max(0, this.selectionIndex-1);
            this.$refs[`item-${this.selectionIndex}`][0].scrollIntoView({ block: 'nearest' });
            e.preventDefault();
            e.stopPropagation();
            return;
          case 'ArrowDown': 
            this.selectionIndex = Math.min(Object.keys(this.selection).length-1, this.selectionIndex+1); 
            this.$refs[`item-${this.selectionIndex}`][0].scrollIntoView({ block: 'nearest' });
            e.preventDefault();
            e.stopPropagation();
            return;
          case 'Enter':
            if (this.selectionIndex >= 0) {
              this.insert({ v: this.selection[this.selectionIndex].key, type: 'var' });
              this.showSelector = false;
              this.$refs.editor.focus();
              e.stopPropagation();
              return;
            }
        }
      },
      codeMousedown(e, k) {
        this.caret = k;
        const { x, width } = e.target.getBoundingClientRect();
        const elementCenterX = x + width / 2;
        if (e.screenX > elementCenterX) this.caret ++;
        if (this.caret > this.code.length) this.caret = this.code.length;
      },
      insert(v) {
        this.code.splice(this.caret, 0, v);
        this.moveRight();
        this.showSelector = false;
      },
      delete(back) {
        const caret = this.caret - (back ? 1:0);
        if (back) this.moveLeft();
        return this.code.splice(caret, 1);
      },
      moveLeft() {
        this.caret = Math.max(0, this.caret-1);
      },
      moveRight() {
        this.caret = Math.min(this.code.length, this.caret+1);
      },
      search(search, str) {
        // strip accents and lowercase
        search = search.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLocaleLowerCase();
        str = str.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLocaleLowerCase();

        // split by words
        const terms = search.replace(/\s+/g, ' ').split(' ');

        return terms.every(t => str.match(t));
      },
      quitSelector() {
        this.showSelector = false;
        this.$refs.editor.focus();
      },
      updateCode() {
        let str = this.value;
        const code = [];
        str = str.replace(/\s+/g, '');
        while (str) {
          const m = str.match(/^({.*?}|.)/);
          if ('{' === m[1][0]) {
            const y = m[1].match(/(-\d+)/);
            if (y) {
              code.push({ v: m[1].replace(/(-\d+)/, ''), type: 'var', year: parseInt(y[1])})
            } else {
              code.push({ v: m[1], type: 'var' });
            }
          } 
          else if (m[1].match(/[/*+-]/)) code.push({ v: m[1], type: 'operator' });
          else if (m[1].match(/[0-9.]/)) code.push({ v: m[1], type: 'digit' });
          
          str = str.replace(/^({.*?}|.)/, '')
        }
        this.code = code;
      },
      emit() {
        this.$emit('input', 
          this.code.map(c => {
            let v = c.v;
            if (c.year) v = v.replace(':', c.year + ':');
            return v;
          }).join('')
        );
      },
      validate() {
        let lastType = '';
        this.code.forEach(c => {
          c.error = false;
          if (c.type === 'var' && ['var', 'digit'].includes(lastType)) {
            c.error = 'Operator missing ?';
          }
          else if (c.type === 'operator' && lastType === 'operator' && c.v !== '-') {
            c.error = 'Double operators ?';
          }
          else if (c.type === 'close' && lastType === 'open') {
            c.error = 'Empty parenthesis';
          }

          lastType = c.type;
        })
        this.code = [...this.code];
      },
    },
    computed: {
      display() {
        this.validate();
        // this.emit();
        return [...this.code, { type: 'placeholder'}];
      },
      selection() {
        // this.selectionIndex = -1;
        if (this.selectionFilter) {
          const sel = [];
          this.vars.forEach(v => {
            if (this.search(this.selectionFilter, v.code + ' ' + v.name)) sel.push(v);
          })
          return sel;
        }

        return this.vars;
      }
    },
    watch: {
      value: {
        handler() {
          this.v = this.value;
          this.updateCode();
        },
        immediate: true,
      },
      v() {
        this.$emit('input', this.v);
      },
    }
}
</script>

<style lang="scss" scoped>


.editor {
  // colors
  --fsa: pink;
  --agg: yellow;
  --inst: limegreen;
  user-select: none;
  outline: 0;

  padding: 5px;
  display: inline-block;
  font-family: monospace;
  border: 1px solid #aaa;
  position: relative;

  input.code {
    position: absolute;
    left: 0; top: 0;
    outline: 0;
  }

  & > span {
    position: relative;
    display: inline-block;
    cursor: text;

    &.var { 
      margin: 0 .3em;
      padding: 2px;  
      font-size: 0.9em;
      &.fsa { color: #88f; }
      &.agg { color: #f66; }
      &.inst { color: #4c4; }
    }

    &.parseerror {
      animation: error 2s linear infinite;
    }
  }

  &:focus .current::before {
    content: "";
    width: 2px;
    background: #555;
    position: absolute;
    left: 0;
    transform: translateX(-1px);
    top: 2px;
    bottom: 2px;
    animation: blinker 1.3s linear infinite;
  }

}

@keyframes blinker {
   0% { opacity: 1; }
  49% { opacity: 1; }
  50% { opacity: 0; }
  99% { opacity: 0; }
}

@keyframes error {
   0% { background: hsl(0deg 100% 90%); }
  50% { background: hsl(0deg 100% 99%); }
 100% { background: hsl(0deg 100% 90%); }
}

.selector {
  position: absolute;
  background: #eee;
  border: 1px solid #888;
  padding: 8px;
  z-index: 1;
  width: 550px;

  & > input {
    padding: 0 4px;
    border: 1px solid red;
  }
  .selection-content {
    max-height: 200px;
    overflow: auto;
    list-style: none;
    padding: 0;

    .selection-list:empty::after {
      content: 'No Result';
    }
  }
  .selection-item {
    font-size: 0.8rem;
    cursor: pointer;

    &.selected {
      background: #d4d0d0;
    }

    .type {
      border: 1px solid #aaa;
      padding: 0 2px;
      background: pink;
      content: 'fsa';
      margin-right: 3px;
      width: 1px;
      line-height: 1;
    }

    .name {
      padding-left: 2px;
    }

    .name > :hover {
      text-decoration: underline;
    }

    &.fsa .type { background: var(--fsa); }
    &.agg .type { background: var(--agg); }
    &.inst .type { background: var(--inst); }
  }
}


</style>