












import Vue from "vue";
import Component from "vue-class-component";
import { Watch } from "vue-property-decorator";

@Component
export default class Memo extends Vue {
  memo = null;
  saving = false;
  loaded = false;
  timeout = 0;

  @Watch("memo")
  onMemoChanged() {
    if (!this.loaded) {
      return;
    }
    // FIXME: use v-model.lazy when vuetify supports it
    // https://github.com/vuejs/vue/issues/6914#issuecomment-503308023
    if (this.timeout) {
      window.clearTimeout(this.timeout);
    }
    this.timeout = window.setTimeout(this.saveMemo, 1000);
  }

  created() {
    this.loadMemo();
  }

  loadMemo() {
    this.$api.get("dashboard/load-memo").then(response => {
      this.memo = response.data.memo;
      this.loaded = true;
    });
  }

  saveMemo() {
    this.saving = true;
    this.$api
      .post("dashboard/save-memo", {
        memo: this.memo,
      })
      .then(() => {
        this.saving = false;
      });
  }
}
