<template>
  <div v-if="investment">
    <validation-observer ref="form" v-slot="{ handleSubmit }">
      <v-form @submit.stop.prevent="handleSubmit(onSubmit)">
        <v-card outlined>
          <v-card-title v-if="investment.id">Editing {{ investment.id }}</v-card-title>
          <v-card-title v-else>Creating new investment</v-card-title>
          <v-card-text>
            <m-field v-model="investment.investee_id" m="investment" a="investee_id"
                    component="v-select" :items="companies" item-value="id" item-text="name" />

            <h2>Rounds</h2>
            <div v-for="(ir, i) in investment.investmentRounds" :key="i">
              <b>Round {{ i + 1 }}</b>
              <v-btn icon x-small @click="investment.investmentRounds.splice(i, 1)">
                <v-icon x-small>fa fa-trash</v-icon>
              </v-btn>

              <v-row>
                <v-col md="3">
                  <field :name="`investmentRounds[${i}].type`"  component="v-select"
                         :items="['fund', 'direct', 'mezzanine']" :label="$t('models.investmentRound.type')" type="date" v-model="ir.type" :rules="rules.investmentRound.type" />
                </v-col>
                <v-col md="4">
                  <field :name="`investmentRounds[${i}].fund_id`" component="v-select" :items="funds" :label="$t('models.investmentRound.fund_id')" item-value="id" item-text="name" v-model="ir.fund_id" :rules="rules.investmentRound.fund_id" />
                </v-col>

                <v-col md="2">
                  <field :name="`investmentRounds[${i}].invested_at`" :label="$t('models.investmentRound.invested_at')" type="number" min="1900" v-model="ir.invested_at" :rules="rules.investmentRound.invested_at" />
                </v-col>
                <v-col md="3">
                  <field :name="`investmentRounds[${i}].amount`" :label="$t('models.investmentRound.amount')" type="number" min="0" v-model="ir.amount" :rules="rules.investmentRound.amount"/>
                </v-col>
              </v-row>
            </div>
            <v-btn x-small @click="addRound">Add a round</v-btn>


          </v-card-text>

          <v-card-actions>
            <v-spacer />
            <v-btn type="submit" text color="primary">{{$t('actions.save')}}</v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </validation-observer>
  </div>
</template>

<script>
import { Component, Prop, Vue } from "vue-property-decorator";
import ImageUploader from 'vue-image-upload-resize';
import formatter from "@/mixins/formatter";

@Component({components: {ImageUploader}, mixins: [formatter]})
export default class InvestmentForm extends Vue {

  @Prop() investment;

  companies = [];
  funds = [];

  created() {
    this.$api.get(`/company?sort=name`).then(res => this.companies = res.data);
    this.$api.get(`/fund?sort=name`).then(res => this.funds = res.data);
  }

  onSubmit() {
    console.log('patching', this.investment)
    const call = this.investment.id
        ? this.$api.patch(`investment/${this.investment.id}`, this.investment)
        : this.$api.post(`investment`, this.investment)

    call.then(j => {
      this.$emit('save', j.data);
    }).catch(err => {
      if (err.response) this.$refs.form.setErrors(err.response.data);
      this.$snack.validationError(err.response.data)
    });
  }

  addRound() {
    this.$set(this.investment, 'investmentRounds', [...(this.investment.investmentRounds || []), {
      invested_at: null,
      amount: 0,
    }]);
  }

  get rules() {
    return this.$store.state.rules;
  }

}
</script>

<style>
h2 {
  margin: 1em 0;
}
</style>
