import {ConclusionGraphDatum, ConclusionGraphSerie} from "@/smartmsi";
import {forecast} from "@/utils/forecast";
import * as Highcharts from "highcharts";

export const isLimitSerie = (serie: ConclusionGraphSerie) => {
    return /^Limite/.test(serie.name);
}

const types = {
    barChart: "column",
    lineChart: "line",
};

const [alpha, beta, gamma] = [.58, 1, .03641];

const YEARS_IN_FORECAST = 3;

const yearToDate = (year: number) => Date.UTC(year, 0, 1);


export const prepareForecastSeries = (conclusionGraphSeries: ConclusionGraphSerie[]) => {
    const series = [];

    for (let i = 1; i < conclusionGraphSeries.length; i += 3) {
        const cgs = conclusionGraphSeries[i];

        const data = Object.values(cgs.conclusionGraphData).map(d => ({...d, value: parseFloat(d.value)}));

        const predictions = forecast(data.map(d => parseFloat(d.value)), alpha, beta, YEARS_IN_FORECAST);

        const finalData = [
            ...data.slice(YEARS_IN_FORECAST),
            ...predictions.map((value, yearIdx) => {
                return {
                    value,
                    year: 2020 + 1 + yearIdx,
                };
            }),
        ] as ConclusionGraphDatum[];

        const serie = {
            name: cgs.name,
            type: types[cgs.type],
            yAxis: 0,
            color: '',
            data: [] as (number|null)[][],
            marker: {enabled: false},
        };

        const range = {
            name: 'Limites ' + cgs.name,
            data: [] as (number|null)[][],
            type: 'arearange',
            color: '',
            lineWidth: 1,
            dashStyle: 'Dot',
            linkedTo: ':previous',
            fillOpacity: 0.1,
            zIndex: 0,
            marker: {
                enabled: false
            }
        };

        if (cgs.color !== 'auto') {
            serie.color = '#' + cgs.color;
            range.color = '#' + cgs.color;
        }

        let factor = .5;
        finalData.forEach((datum, yearIdx) => {
            serie.data.push([yearToDate(datum.year), Math.min(10, Math.max(0, datum.value))]);

            if (cgs.type === 'barChart') {
                return;
            }

            let inf, sup;
            if (yearIdx < finalData.length - YEARS_IN_FORECAST - 1) {
                inf = null;
                sup = null;
            } else if (yearIdx === finalData.length - YEARS_IN_FORECAST - 1) {
                inf = sup = datum.value;
            } else {
                factor += .5;
                inf = Math.min(10, Math.max(0, datum.value * (1 - (factor * gamma))))
                sup = Math.min(10, Math.max(0, datum.value * (1 + (factor * gamma))))
            }

            range.data.push([yearToDate(datum.year), inf, sup])
        });

        series.push(serie);
        series.push(range);
    }

    return series;
}

const upDownBarsTooltipFormatter = function (
    this: Highcharts.TooltipFormatterContextObject
) {
    if (!this.points) {
        return;
    }

    const html = document.createElement("div");
    const title = document.createElement("span");
    title.textContent = new Date(this.x).getFullYear().toString();

    const serie1 = document.createElement("div");
    const name1 = document.createElement("span");
    name1.textContent = this.points[1].series.getName() + ": ";
    const value1 = document.createElement("span");
    value1.textContent = this.points[1].y.toString();
    value1.setAttribute("style", "font-weight: bold");
    serie1.appendChild(name1);
    serie1.appendChild(value1);
    const serie2 = document.createElement("div");
    const name2 = document.createElement("span");
    name2.textContent = this.points[2].series.getName() + ": ";
    const value2 = document.createElement("span");
    value2.textContent = this.points[2].y.toString();
    value2.setAttribute("style", "font-weight: bold");
    serie2.appendChild(name2);
    serie2.appendChild(value2);

    html.appendChild(title);
    html.appendChild(document.createElement("br"));
    html.appendChild(serie1);
    html.appendChild(document.createElement("br"));
    html.appendChild(serie2);

    return html.innerHTML;
};


export default {
    yearToDate,
    isLimitSerie,
    prepareForecastSeries,
    upDownBarsTooltipFormatter,
}