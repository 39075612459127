
















import Component, { mixins } from 'vue-class-component'
import { UpdateMixin } from './mixins/update';

@Component
export default class App extends mixins( UpdateMixin ) {
  metaInfo = {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: 'Default Title',
  };
  mounted() {
    const theme = localStorage.getItem("dark");
    if (theme) {
      this.$vuetify.theme.dark = theme === "true";
    } else if (
      window.matchMedia &&
      window.matchMedia("(prefers-color-scheme: dark)").matches
    ) {
      this.$vuetify.theme.dark = true;
      localStorage.setItem("dark", this.$vuetify.theme.dark.toString());
    }
  }
}
