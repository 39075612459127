import store from "../store";
import {ISnack} from "@/smartmsi";

const Snack: ISnack = {
  success(text: string, html = false): void {
    store.state.snack[html ? 'html' : 'text'] = text;
    store.state.snack.color = 'success';
    store.state.snack.show = true;
  },
  error(text: string, html = false): void {
    store.state.snack[html ? 'html' : 'text'] = text;
    store.state.snack.color = 'error';
    store.state.snack.show = true;
  },
  validationError(errors: Record<string, string[]>): void {

    let html = '<b>Validation Errors</b><br>';
    Object.keys(errors).forEach(attr => {
      html += `<br>${attr}:&nbsp;<i>${errors[attr].join(' | ')}</i>`;
    });

    store.state.snack.html = html;
    store.state.snack.color = 'error';
    store.state.snack.show = true;
  }
}

export default Snack;