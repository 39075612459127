<template>
  <v-alert v-if="error" prominent type="error">
    <v-row align="center">
      <v-col class="grow" style="white-space: pre-wrap">
        <b>ERROR #{{ error.code }}</b><br/> {{ error.message }}
      </v-col>
    </v-row>
  </v-alert>
</template>

<script>
import {Component, Prop, Vue} from "vue-property-decorator";
import formatter from "@/mixins/formatter";

@Component({mixins: [formatter]})
export default class UploadError extends Vue {
  @Prop({default: null}) error;
}
</script>