import Vuetify from "@/plugins/vuetify";
import { MutationTree } from "vuex";
import { AppState } from "@/smartmsi";

export default {
  setDrawer(state, drawer: boolean) {
    state.drawer = drawer;
  },
  setDark: (state, dark: boolean) => {
    state.dark = dark;
    localStorage.setItem("dark", "" + dark);
    Vuetify.framework.theme.dark = dark;
  },
  toggleDrawer(state) {
    state.drawer = !state.drawer;
  },
} as MutationTree<AppState>;
