import Vue from "vue";

import * as rules from "vee-validate/dist/rules";
import { extend, localize, validate, ValidationObserver, ValidationProvider } from "vee-validate";
import en from "vee-validate/dist/locale/en.json";
import fr from "vee-validate/dist/locale/fr.json";
import urlRegex  from 'url-regex';
import {PartialI18nDictionary} from "@/smartmsi";

// register rules
Object.keys(rules).forEach(rule => {
  // @ts-ignore
  extend(rule, rules[rule]);
});

extend('url', {
  message:  () => 'Invalid Url.',
  validate: (value: string) => urlRegex({exact: true}).test(value),
});

// FIXME: WORKS ONLY WITH name and did not find a proper way to pass error to message
let lastErrors: string[] = [];
extend('each', {
  message: () => lastErrors[0],
  validate: async(value: string, args: any) => {
    const rules = args[0].name;
    const { valid, errors } = await validate(value, rules);
    lastErrors = errors;
    return valid;
  },
});

// customize error messages 
[fr, en].forEach((l: PartialI18nDictionary) => {
  Object.keys(l.messages).forEach(m => {
    l.messages[m] = l.messages[m]
        .replace(/^Le champ {_field_} /, 'Ce champ ')
        .replace(/^The {_field_} field /, 'This field ');
  });
});

// assign messages
localize({en, fr});


// Install VeeValidate components globally
Vue.component("ValidationObserver", ValidationObserver);
Vue.component("ValidationProvider", ValidationProvider);
