<template>
  <v-card>
    <v-card-title>
      <span class="text-h5">{{title}}</span>
    </v-card-title>
    <v-card-text>
      <!-- parent -->
      <div v-for="(l1, idx1) in list" :key="l1.code">
        <label class="tr l1" :class="{ odd: idx1%2, checked: l1.checked, [l1.type]: true }">
          <input type="checkbox" v-model="l1.checked" />
          <div class="th code">{{ l1.code }}</div>
          <div class="th name">{{ translateName(l1) }}</div>
        </label>

        <div v-if="l1.checked">

          <!-- children -->
            <div v-for="(l2, idx2) in l1.children" class="" :key="l2.code">
              <label class="tr l2" :class="{ odd: idx2%2, checked: l2.checked, [l2.type]: true }">
                <input type="checkbox" v-model="l2.checked" />
                <div class="th code">{{ l2.code }}</div>
                <div class="th name">{{ translateName(l2) }} </div>
              </label>

              <!-- grand children ? -->
              <div v-if="l2.checked">
                <label v-for="(l3, idx3) in l2.children" :key="l3.code" class="tr l3" :class="{ odd: idx3%2, checked: l3.checked, [l3.type]: true }" >
                  <input type="checkbox" v-model="l3.checked" />
                  <div class="td code">{{ l3.code }}</div>
                  <div class="td name"> {{ translateName(l3) }} </div>
                </label>
              </div>
            </div>

        </div>
      </div>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="blue darken-1" text @click="$emit('close')" > Cancel </v-btn>
      <v-btn color="blue darken-1" text @click="save()" > Save </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>

import formatter from '@/mixins/formatter';

// filter deep array only keeping checked branches
const checkedOnly = (list) => {
  const res = JSON.parse(JSON.stringify(list.filter(p => p.checked)));
  res.forEach(r => delete r.checked);

  res.forEach(x => {
    if (x.children) x.children = checkedOnly(x.children);
  })

  return res;
}

export default {
    name: 'AddBranch',
    mixins: [formatter],
    props: ['branches', 'branch'],
    data() {
      const list = JSON.parse(JSON.stringify(this.branches));
      list.forEach(l => l.checked = false)
      return {
        list,
        selected: null,
        subSelected: null,
        financialStatements: [],
      }
    },
    methods: {
      save() {
        const json = checkedOnly(this.list);
        this.$emit('save', json);
      }
    },
    computed: {
      title() {
        switch (this.branch) {
          case 'financialStatements': return 'Add existing Financial Statements';
          case 'financialHeadings': return 'Add existing Financial Headings';
          case 'financialAccounts': return 'Add existing Financial Accounts';
          case 'aggregateHeadings': return 'Add existing Aggregate Headings';
          case 'aggregates': return 'Add existing Aggregates';
          case 'axes': return 'Add existing Axes';
          case 'themes': return 'Add existing Themes';
          case 'instruments': return 'Add existing Instruments';
        }
        return 'hum?';
      }
    },
}
</script>

<style lang="scss" scoped>

@import "src/scss/spreadsheet";

.tr {
  
  &.fac, &.instrument {

      & > input[type=checkbox] {
        transform: scale(0.8);
      }

  }

  & > input[type=checkbox] {
    margin: 0 4px 0 8px;
    filter: grayscale(0.8) contrast(1.4);
    opacity: 0.5;
  }

  &.checked > input[type=checkbox] {
    opacity: 1;
  }
}

.th, .td {
  padding: 4px;

  &.formula::before {
    content: '=>';
    margin: 0.5em;
  }

  &.formula span:not(:hover) {
    opacity: 0.8;
  }
}

/*  branch */

.tr {
  user-select: none;  
}

.tr:not(.checked) {

  .code, .name {
    color: #777;
    text-shadow: 0 1px #ffffffc9;
  }

  &:hover .code, &:hover .name {
    color: #555;
  }
}

</style>
