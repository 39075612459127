

























import {Component, Prop, Vue} from "vue-property-decorator";
import formatter from "@/mixins/formatter";
import {Company} from "@/smartmsi";
import {downloadToDisk} from "@/utils/download";


@Component({mixins: [formatter]})
export default class DownloadFinancialStatements extends Vue {

  @Prop() company!: Company;

  startYear = 0;
  endYear = 0;
  generating = false;

  created() {
    this.startYear = this.minYear;
    this.endYear = this.maxYear;
  }

  get minYear(): number {
    return this.company.availableYears[0];
  }

  get maxYear(): number {
    return this.company.availableYears[this.company.availableYears.length - 1];
  }

  get canDownload(): boolean {
    return !this.generating &&
        this.startYear > 0 &&
        this.endYear > 0;
  }

  download() {
    this.generating = true;
    this.$api.post('company/data-excel?id=' + this.company.id, {
      startYear: this.startYear,
      endYear: this.endYear,
    }, {
      responseType: 'blob',
    }).then(response => {
      this.generating = false;
      downloadToDisk(response);
    });
  }

}
