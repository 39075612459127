




















import { Prop, Vue } from "vue-property-decorator";
import { MsiIndex } from "@/smartmsi";
import getUnicodeFlagIcon from "country-flag-icons/unicode";
import Component from "vue-class-component";

@Component
export default class IndexListItem extends Vue {
  @Prop({required: true}) index!: MsiIndex;

  flag(code: string) {
    return getUnicodeFlagIcon(code);
  }
}
