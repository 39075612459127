



































































































































import { Component, Prop, Vue } from "vue-property-decorator";
import formatter from "@/mixins/formatter";
import { CertificationAuthority, Company, Entity } from "@/smartmsi";
import { downloadToDisk } from "@/utils/download";
import VueTheMask from "vue-the-mask";

Vue.use(VueTheMask);

@Component({mixins: [formatter]})
export default class DownloadAnalysis extends Vue {

  @Prop() company!: Company;
  @Prop() data!: Array;

  currentTab = 0;

  selectedInstruments: Record<number, number> = {};

  entities: CertificationAuthority[] = [];
  auditTypes = [{id: 'followup', name: 'Follow Up'}, {id: 'major', 'name': 'Major Audit'}];
  languages = [{code: 'fr', 'label': "Français"}, {code: 'en', label: "English"}];
  generating = false;
  generationType = 'full';
  generationTypes = {
    excel: "Fichier d'analyse [.xlsx]",
    // docx= "Fichier Word [.docx]",
    full: "Fichier Word + PowerPoint + Excel lié [.zip]"
  };
  modal = false;
  minYear = this.company.availableYears[0];
  maxYear = this.company.availableYears[this.company.availableYears.length - 1];
  config = {
    target_year: this.maxYear,
    forecast: 3,
    instrument_start: Math.max(this.minYear, this.maxYear - 4),
    // From what year do we start in the Excel file
    analysis_start: Math.max(this.minYear, this.maxYear - 9),
    theme_start: Math.max(this.minYear, this.maxYear - 9),
    theme_table_start: Math.max(this.minYear, this.maxYear - 7),
    conclusion_start: Math.max(this.minYear, this.maxYear - 9),
    certification_authority_id: 3,

    destinationFolder: '/Users/didou/Dev/sites/mehdi/smartmsi.lcl/www/',

    language: 'fr',
    document_date: (new Date()).toISOString().split('T')[0].split('-').slice(0, 2).join('-'),
    accent_color: 'FFC501',
    audit_type: 'major',
  };

  colorMask = '!#XXXXXXXX';
  menu = false;

  created() {
    this.$api.get('certification-authority').then(response => {
      this.entities = response.data;
      this.config.certification_authority_id = this.entities[2].id;
    });
    this.config.destinationFolder = this.$store.state.user.preferences.destinationFolder;

    this.data.axes.forEach((axe: Entity) => {
      axe.children.forEach(theme => {
        this.selectedInstruments[theme.id] = theme.children[0].id;
      })
    })
  }

  get swatchStyle() {
    return {
      backgroundColor: this.config.accent_color,
      cursor: 'pointer',
      height: '22px',
      width: '22px',
      borderRadius: this.menu ? '50%' : '4px',
      transition: 'border-radius 200ms ease-in-out'
    }
  }

  download() {
    this.generating = true;
    this.$api.post('company/download-analysis?id=' + this.company.id, {
      type: this.generationType,
      config: this.config,
      selectedInstruments: this.selectedInstruments
    }, {
      responseType: 'blob',
    }).then(function (response) {
      console.log('SUCCESS!!');
      downloadToDisk(response);
    }).catch(function () {
      console.log('FAILURE!!');
    }).finally(() => {
      this.generating = false;
    });
  }

}
