<template>
  <v-snackbar v-model="$store.state.snack.show" :color="$store.state.snack.color">
    <div v-if="$store.state.snack.html" v-html="$store.state.snack.html"></div>
    <div v-else-if="$store.state.snack.text" v-text="$store.state.snack.text"></div>
    <v-btn text @click="$store.state.snack.show = false">Close</v-btn>
  </v-snackbar>
</template>

<script>
import { Vue, Component } from "vue-property-decorator";

@Component()
export default class Snack extends Vue {
}
</script>