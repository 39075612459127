// https://vuex.vuejs.org/en/actions.html
import api from "@/services/api";
import { ActionTree } from "vuex";
import { GlobalState } from "@/smartmsi";
import router from '@/router';
import i18n from "@/services/i18n";

// The login action passes vuex commit helper that we will use to trigger mutations.
export default {
  login({ commit }, userData) {
    return new Promise((resolve, reject) => {
      commit("authRequest");
      api.defaults.withCredentials = true;
      api
        .post("/auth/login", {
          email: userData.email,
          password: userData.password,
        })
        .then(response => {
          const { token, user } = response.data;
          api.defaults.withCredentials = false;

          localStorage.setItem("access_token", token);
          localStorage.setItem("user", JSON.stringify(user));
          api.defaults.headers.common["Authorization"] = "Bearer " + token;
          // mutation to change state properties to the values passed along
          commit("authSuccess", { token, user });
          resolve(response);
        })
        .catch(err => {
          commit("authError");
          api.defaults.withCredentials = false;
          localStorage.removeItem("access_token");
          reject(err);
        });
    });
  },
  sudo({ commit }, userId) {
    return new Promise((resolve, reject) => {
      api.defaults.withCredentials = true;
      api.get(`/auth/sudo?id=${userId}`).then(response => {
        api.defaults.withCredentials = false;
        console.log("sudo success");
        const { token, user } = response.data;

        const currentUser = localStorage.getItem("user");
        if (!currentUser) {
          reject("sudoError");
          return;
        }
        localStorage.setItem("impersonator", currentUser);
        localStorage.setItem("access_token", token);
        localStorage.setItem("user", JSON.stringify(user));
        api.defaults.headers.common["Authorization"] = "Bearer " + token;

        commit("sudoSuccess", { user });

        resolve(user);
      });
    });
  },
  endSudo({ commit }) {
    return new Promise(resolve => {
      api.defaults.withCredentials = true;
      api
        .post(
          "/auth/refresh-token",
          { endSudo: true },
          { withCredentials: true }
        )
        .then(response => {
          api.defaults.withCredentials = false;
          console.log(response.data);
          const { token, user } = response.data;

          localStorage.setItem("access_token", token);
          localStorage.setItem("user", JSON.stringify(user));
          localStorage.removeItem("impersonator");
          api.defaults.headers.common["Authorization"] = "Bearer " + token;

          commit("authSuccess", { token, user });
          commit("endSudoSuccess");

          resolve(user);
        })
        .catch(() => {
          api.defaults.withCredentials = false;
        });
    });
  },
  logout({ commit }) {
    return new Promise(resolve => {
      commit("logout");
      localStorage.removeItem("access_token");
      delete api.defaults.headers.common["Authorization"];
      router.push('/login');
      resolve();
    });
  },
  refreshToken({ commit }) {
    return new Promise(resolve => {
      api.defaults.withCredentials = true;
      api
        .post("/auth/refresh-token", {}, { withCredentials: true })
        .then(response => {
          api.defaults.withCredentials = false;

          const { token, user } = response.data;
          localStorage.setItem("access_token", token);
          //api.defaults.headers.common["Authorization"] = "Bearer " + token;
          commit("authSuccess", { token, user });

          resolve(token);
        })
        .catch(error => {
          api.defaults.withCredentials = false;

          commit("logout");
          console.error(error);
        });
    });
  },
  getTableList({ commit }, tableName) {
    api
      .get(`/${tableName}`)
      .then(response => {
        console.log(response);
        const tableList = response.data.Keys;
        commit("setTableList", { tableList });
      })
      .catch(error => console.log(error));
  },
  addError({ state }, error) {
    state.errors.push(error);
  },
  clearErrors({ state }) {
    state.errors = [];
  },
  changeLocale(state, locale) {
    api.post(`site/change-locale`, {
      locale
    }).then((res) => {
      const finalLocale = res.data;
      localStorage.setItem('language', finalLocale);
      i18n.locale = finalLocale;
    });
  },
  updateTableItem(state, tableData) {
    return new Promise((resolve, reject) => {
      const method = tableData.method;
      api({
        url: `/${tableData.endpoint}`,
        method,
        data: tableData.tableItem,
      })
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          console.log(error);
          reject(error);
        });
    });
  },
  loadCountries({ state }) {
    return api.get('/country').then(j => state.data.country = j.data);
  },
  loadLegalTypologies({ state }) {
    return api.get('/legal-typology').then(j => state.data.legalTypology = j.data);
  },
  loadStockMarkets({ state }) {
    return api.get('/stock-market').then(j => state.data.stockMarket = j.data);
  },
  loadEntities({ state }) {
    return api.get('/site/data?model=Entity').then(j => state.data.Entities = j.data);
  },

  // autoRefreshToken ({ commit }) {
  //   return new Promise((resolve, reject) => {
  //     setInterval(function () {
  //       // code goes here that will be run every 20 minutes.
  //       api.get('/refresh')
  //         .then(response => {
  //           const token = response.data.access_token
  //           localStorage.setItem('token', token)
  //           axios.defaults.headers.common['Authorization'] = 'Bearer ' + token
  //           commit('auth_success', { token })
  //           resolve(response)
  //         })
  //         .catch(error => {
  //           console.log('refresh token error')
  //           console.log(error)
  //           reject(error)
  //         })
  //     }, 1200000)
  //   }
  //   )
  // },
} as ActionTree<GlobalState, GlobalState>;
