






import Component from "vue-class-component";
import Vue from "vue";
import * as Highcharts from "highcharts";
import HCData from "highcharts/modules/data";
import chartExport from "highcharts/modules/exporting";
import dataExport from "highcharts/modules/export-data";
import stockInit from 'highcharts/modules/stock'
import indicators from 'highcharts/indicators/indicators-all';
import { Prop } from "vue-property-decorator";
import {Chart} from "highcharts-vue";
import { MsiIndex } from "@/smartmsi";
import { startOfYear, subMonths } from "date-fns";
import CONFIG from "@/config";

dataExport(Highcharts);
chartExport(Highcharts);
stockInit(Highcharts);
indicators(Highcharts);
HCData(Highcharts);

@Component({components: {Chart}})
export default class MsiIndexChart extends Vue
{
  @Prop({required: true}) index !: MsiIndex;
  @Prop({required: false, default: false}) withMsi !: boolean;
  @Prop({required: false, default: 20000}) msiBase !: number;
  @Prop({required: false, default: false}) pollingEnabled !: boolean;

  pollingRate = 2;

  get chartOptions() {
    return {
      chart: {
        type: 'spline',
        height: 800
      },
      title: {
        text: this.index.stockExchange?.name
      },
      subtitle: {
        text: this.index.stockExchange?.isin
      },
      credits: {
        enabled: false,
      },
      yAxis: {
        opposite: false
      },
      colors: ["#2fff00", "#f69121", "#f69121", "#f69121", "#f69121", "#f69121", "#f69121", "#f69121", "#f69121", "#f69121", "#f69121", "#f69121", "#f69121", "#f69121", "#f69121", "#f69121", "#f69121", "#f69121", "#f69121", "#f69121", "#f69121", "#f69121", "#f69121", "#f69121", "#f69121", "#f69121", "#f69121", "#f69121", "#f69121", "#f69121", "#f69121", "#f69121", "#f69121", "#f69121", "#f69121", "#f69121", "#f69121", "#f69121", "#f69121", "#f69121", "#f69121", "#f69121", "#f69121", "#f69121", "#f69121", "#f69121", "#f69121", "#f69121", "#f69121", "#f69121", "#f69121", "#f69121", "#f69121", "#f69121", "#f69121", "#f69121", "#f69121", "#f69121", "#f69121", "#f69121", "#f69121", "#f69121", "#f69121", "#f69121", "#f69121", "#f69121", "#f69121", "#f69121", "#f69121", "#f69121", "#f69121", "#f69121", "#f69121", "#f69121", "#f69121", "#f69121", "#f69121", "#f69121", "#f69121", "#f69121", "#f69121", "#f69121", "#f69121", "#f69121", "#f69121", "#f69121", "#f69121", "#f69121", "#f69121", "#f69121", "#f69121", "#f69121", "#f69121", "#f69121", "#f69121", "#f69121", "#f69121", "#f69121", "#f69121", "#f69121", "#f69121", "#f69121", "#f69121", "#f69121", "#f69121", "#f69121", "#f69121", "#f69121", "#f69121", "#f69121", "#f69121", "#f69121", "#f69121", "#f69121", "#f69121", "#f69121", "#f69121", "#f69121", "#f69121", "#f69121", "#f69121", "#f69121", "#f69121", "#f69121", "#f69121", "#f69121", "#f69121", "#f69121", "#f69121", "#f69121", "#f69121", "#f69121", "#f69121", "#f69121", "#f69121", "#f69121", "#f69121", "#f69121", "#f69121", "#f69121", "#f69121", "#f69121", "#f69121", "#f69121", "#f69121", "#f69121", "#f69121", "#f69121", "#f69121", "#f69121", "#f69121", "#f69121", "#f69121", "#f69121", "#f69121", "#f69121", "#f69121", "#f69121", "#f69121", "#f69121", "#f69121", "#f69121", "#f69121"],
      data: {
        csvURL: `${CONFIG.api.url}/stock-exchange/csv/${this.index.stockExchange.id}?withMsi=${this.withMsi ? 1 : 0}&base=${this.msiBase}`,
        parsed: (data: number[][]) => {
          this.broadcastData(data);
        },
        enablePolling: this.pollingEnabled,
        dataRefreshRate: this.pollingRate,
      }
    };

  }

  broadcastData(data) {
    const _3M = subMonths(new Date(), 3).getTime();
    const _6M = subMonths(new Date(), 6).getTime();
    const YTD = subMonths(new Date(), 12).getTime();
    const JAN = startOfYear(new Date()).getTime();

    const msiValues = {
      '3M': 0,
      '6M': 0,
      'JAN': 0,
      'YTD': 0,
      'ALL': data[1][1],
    };
    const cacValues = {
      '3M': 0,
      '6M': 0,
      'JAN': 0,
      'YTD': 0,
      'ALL': data[2] && data[2][1],
    };

    data[0].forEach((timestamp, idx) => {
      if (!msiValues['JAN'] && JAN < timestamp) {
        msiValues['JAN'] = data[1][idx];
        cacValues['JAN'] = data[2] && data[2][idx];
      } else if (!msiValues['3M'] && _3M < timestamp) {
        msiValues['3M'] = data[1][idx];
        cacValues['3M'] = data[2] && data[2][idx];
      } else if (!msiValues['6M'] && _6M < timestamp) {
        msiValues['6M'] = data[1][idx];
        cacValues['6M'] = data[2] && data[2][idx];
      } else if (!msiValues['YTD'] && YTD < timestamp) {
        msiValues['YTD'] = data[1][idx];
        cacValues['YTD'] = data[2] && data[2][idx];
      }
    })

    const last = [];
    last.push(data[1][data[1].length - 1]);

    if (this.withMsi) {
      last.push(data[2][data[2].length - 1]);
    }

    this.$emit("setData", {
      samples: [msiValues, cacValues],
      last
    });
  }




}
