































import { Vue, Component, Prop } from "vue-property-decorator";

@Component
export default class BaseWidget extends Vue {
  @Prop({ default: "Widget title" }) readonly title!: string;
  @Prop({ default: "fa-flag" }) readonly icon!: string;
  @Prop({default: ""}) readonly cardTextClass!: string;

  collapsed = false;
  shown = true;

  get hasFooterSlot() {
    return !!this.$slots["footer"];
  }
}
