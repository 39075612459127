




import * as flags from 'country-flag-icons/string/3x2';
import {Component, Prop, Vue} from "vue-property-decorator";

@Component
export default class CountryIndex extends Vue {
  @Prop() code!: string;

  svg() {
    return (flags as Record<string, string>)[this.code];
  }
}
