import Vue from "vue";
import VueRouter from "vue-router";
import NProgress from "nprogress";

import store from "../store";
import routes from "./routes";

Vue.use(VueRouter);

// Create a new router
const router = new VueRouter({
  base: process.env.BASE_URL,
  mode: "history",
  routes,

  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    if (to.hash) {
      return { selector: to.hash };
    }
    return { x: 0, y: 0 };
  },
});

// Route guard checks to see if you are logged in, if not reroutes to login
// to is where you are going, matched.some is to find which routes have requiresAuth
router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (store.getters.authorized) {
      next();
      return;
    }
    next("/login");
  } else {
    next();
  }
});

router.beforeResolve((to, from, next) => {
  if (to.path) {
    NProgress.start();
  }
  next();
});

router.afterEach(() => {
  NProgress.done();
});


// Bootstrap Analytics
// Set in .env
// https://github.com/MatteoGabriele/vue-analytics
/*if (process.env.GOOGLE_ANALYTICS) {
    Vue.use(VueAnalytics, {
        id: process.env.GOOGLE_ANALYTICS,
        router,
        autoTracking: {
            page: process.env.NODE_ENV !== 'development'
        }
    })
}*/

export default router;
