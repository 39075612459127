



















import Vue from "vue";
import Component from "vue-class-component";

@Component
export default class Changelog extends Vue {
  memo = null;
  saving = false;
  loaded = false;
  timeout = 0;
}
