import store from "../store";
import { RouteConfig } from "vue-router";

const authRoute = (path: string, page: string) => ({
  path,
  meta: {
    requiresAuth: true,
  },
  props: path.includes(":"),
  component: () => import(`@/pages/${page}.vue`),
});

export default [
  {
    path: "*",
    meta: {
      requiresAuth: true,
    },
    redirect: {
      path: "/",
    },
  },
  // Login layout
  {
    path: "/login",
    meta: {
      requiresAuth: false,
    },
    component: () =>
      import(/* webpackChunkName: "routes" */ `@/layouts/Login.vue`),
    // redirect if already signed in
    beforeEnter: (to, from, next) => {
      if (store.getters.authorized) {
        next("/");
      } else {
        next();
      }
    },
    children: [
      {
        path: "",
        component: () => import(`@/pages/Login.vue`),
      },
    ],
  },
  // add any extra routes that you want rendered in the dashboard as a child below. Change toolbar names here
  {
    path: "/",
    meta: {
      name: "Dashboard View",
      requiresAuth: true,
    },
    component: () => import(`@/layouts/Main.vue`),
    children: [
      authRoute("", "Dashboard"),
      authRoute("portfolio", "portfolio/Index"),
      authRoute("stock", "stock/Index"),
      authRoute("stock/:indexId", "stock/View"),
      authRoute("company", "company/Index"),
      authRoute("company/create", "company/Form"),
      authRoute("company/:companyId/edit", "company/Form"),
      authRoute("company/:companyId", "company/View"),
      authRoute("activity", "activity/Index"),
      authRoute("sector", "sector/Index"),
      authRoute("stock-market", "stock-market/Index"),
      authRoute("certification-authority", "certification-authority/Index"),
      authRoute("country", "country/Index"),
      authRoute("region", "region/Index"),
      authRoute("fund", "fund/Index"),
      authRoute("variations/:variationId", "variation/View"),
      authRoute("variations", "variation/Index"),
      authRoute("user", "user/Index"),
      authRoute("rbac", "rbac/Index"),
      /*{
        path: "notifications",
        meta: {
          name: "Notifications",
          requiresAuth: true,
        },
        component: () => import(`@/components/DashViews/Notifications.vue`),
      },*/
      {
        path: "*",
        meta: {
          requiresAuth: true,
        },
        component: () => import(`@/components/error/NotFound.vue`),
      },
    ],
  },
] as Array<RouteConfig>;
