import {AxiosResponse} from "axios";

export const downloadToDisk = (response: AxiosResponse) => {
    const fileURL = window.URL.createObjectURL(new Blob([response.data], {
        type: response.headers['content-type']
    }));
    const fileLink = document.createElement('a');

    fileLink.href = fileURL;
    fileLink.setAttribute('download', response.headers['x-msi-filename']);
    document.body.appendChild(fileLink);

    fileLink.click();
}