



























































import Vue from "vue";
import Component from "vue-class-component";
import { State } from "vuex-class";
import { AuthUser, AuthSession } from "@/smartmsi";
import parser from "ua-parser-js";

@Component
export default class Page extends Vue {
  @State user!: AuthUser;
  @State impersonator!: AuthUser;

  users = [];
  sessions: AuthSession[] = [];
  impersonated: AuthUser | null = null;

  sudo() {
    this.$store
      .dispatch("sudo", this.impersonated!.id);
  }

  endSudo() {
    this.$store
      .dispatch("endSudo");
  }

  getAllUsers() {
    this.$api.get("auth/users").then(({ data }) => {
      this.users = data;
    });
  }

  browserIcon(browser: string) {
    const icons: Record<string, string> = {
      Chrome: "fab fa-chrome",
      Firefox: "fab fa-firefox",
    };

    return icons[browser] ?? "fa-globe";
  }

  osIcon(os: string) {
    const icons: Record<string, string> = {
      "Mac OS": "fab fa-apple",
      "Windows": "fab fa-windows",
      "Linux": "fab fa-linux",
      "Android": "fab fa-android",
    };
    console.log(os);

    return icons[os] ?? "fa-question";
  }

  getSessions() {
    this.$api.get("auth/sessions").then(({ data }) => {
      this.sessions = data;
      this.sessions.map(item => (item.parsed = parser(item.ua)));
    });
  }

  created() {
    this.getAllUsers();
    this.getSessions();
  }
}
