export const dataAverage = (data: {}, minus = 0) => {
    let total = 0;
    const values = Object.values(data).slice(0, Object.values(data).length - minus);

    values.forEach(datum => {
        total += parseFloat(datum.value);
    });

    return total / values.length;
}
