// https://vuex.vuejs.org/en/getters.html

// authorized lets you know if the token is true or not
import { GetterTree } from "vuex";
import { GlobalState } from "@/smartmsi";

export default {
  authorized: state => !!state.token,
  authstatus: state => state.authStatus,
  code2id: state => (model: string, code: string) => state.data[model].find(c => c.code === code)?.id,
  id2model: state => (model: string, id: number) => state.data[model].find(c => c.id == id),
  codes: state => (model: string) => state.data[model].map(m => ({ 'value': m.id, 'text': m.code })),
} as GetterTree<GlobalState, GlobalState>;
