import { Entity, Cell } from '@/smartmsi';
import { fromExcelFormat } from '@/utils/excel-format';

export default {
  methods: {
    toPrice(v: number, defaultValue = "N.C") {
      v = parseInt(v.toString());
      if (!v) {
        return defaultValue;
      }

      const F = new Intl.NumberFormat("fr-FR", {
        style: "currency",
        currency: "TND",
      });

      return F.format(v).replace(/,\d+/, "").replace("TND", "DT");
    },
    toKms(v: number, defaultValue = 0) {
      if (!v) return defaultValue;

      const F = new Intl.NumberFormat("fr-FR", {
        style: "currency",
        currency: "TND",
      });

      return F.format(v).replace(/,\d+/, "").replace("TND", "KM");
    },
    translate(field: Record<string, string>): string {
      if (!field) {
        return 'translate(null?)';
      }
      // @ts-ignore
      return field[this.$i18n.locale] ?? field['en'] ?? field[Object.keys(field)[0]];
    },
    translateName(e: Entity | Cell): string {
      // @ts-ignore
      return e['name_' + this.$i18n.locale] ?? e['name_en'] ?? e['name_fr'];
    },
    i18nSort(a: Record<string, string>, b: Record<string, string>) {
      if (this.translate(a) > this.translate(b)) return 1;
      if (this.translate(a) < this.translate(b)) return -1;
      return 0;
    },
    formula(str: string): string {
      if (str === null || str === '') {
        return '<span>## NO FORMULA ##</span>';
      }

      let depth = 0;
      return str
      .replace(/[()]/g, match => {
        if ('(' === match) {
          return `<span class="depth${depth++}">(<_span>`;
        } else {
          return `<span class="depth${--depth}">)<_span>`;
        }
      })
      .replace(/{((?:fsa|agg)(?:-\d+)?:\d+)}/g, (match, group) => {

        const [part1, id] = group.split(':');
        const [abbr, years] = part1.split('-'); 
        let val;

        // @ts-ignore
        val = this.$store.getters.id2model('Entities', parseInt(id));

        if (val) {
          val = this.translateName(val);
        } else {
          val = '?';
        }
        return `<var class="${abbr}">${val}${years ? ` (MINUSYEARS${years})` : ''}<_var>`;
      })
      .replace(/[+*/-]/g, match => `<span class="operator">${match}</span>`)
      .replace(/<_/g, '</')
      .replace(/MINUSYEARS/g, 'Y-')
    },
    excelFormat: fromExcelFormat,
  },
} ;