





















import {Vue, Component, Prop} from "vue-property-decorator";

@Component
export default class MiniStat extends Vue {
  @Prop({required: false}) supTitle!: string;
  @Prop({required: false}) subTitle!: string;
  @Prop({required: false}) title!: string;
  @Prop({required: false}) icon!: string;
  @Prop({required: false}) color!: string;

}
