<template>
  <picture>
    <source :srcset="webpSrc" type="image/webp" />
    <!--suppress HtmlRequiredAltAttribute -->
    <img :src="src" v-bind="$attrs" :class="classObject" />
  </picture>
</template>

<script>
export default {
  props: {
    src: {
      type: String,
      required: true,
    },
    fluid: {
      type: Boolean,
      required: false,
      default: false,
    },
    rounded: {
      type: Boolean,
      required: false,
      default: false,
    },
    circle: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    classObject() {
      const classes = [];
      if (this.fluid) {
        classes.push('img-fluid');
      }
      if (this.circle) {
        classes.push('rounded-circle');
      }
      return classes;
    },
    webpSrc() {
      return this.src.replace(/\.(jpe?g|png)/, '.webp');
    }
  }
}
</script>