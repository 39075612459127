<template>
  <v-btn-toggle class="th" style="flex:1" active-class="none">
    <v-btn v-for="(button, b) in buttons" :key="b" x-small @click="$emit('click', b)">
      <v-icon x-small v-for="(icon, i) in button" :key="i">fa-chevron-{{icon}}</v-icon>
    </v-btn>
  </v-btn-toggle>
</template>

<script>
import { Vue, Component, Prop} from "vue-property-decorator";

@Component
export default class Expanders extends Vue {
  @Prop() levels;

  get buttons() {
    if (this.levels === 2) {
      return [
        ['right'],
        ['down'],
      ];
    }

    return [
      ['right', 'right'],
      ['down', 'right'],
      ['down', 'down'],
    ];

  }
}
</script>
