
















import {Vue, Component, Prop} from "vue-property-decorator";

@Component
export default class Card extends Vue {
  @Prop() title!: string;
}
