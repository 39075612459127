// @ts-ignore
import localConfig from "./config-local";

const config = {
  defaults: {
    theme: "light",
  },
  api: {
    url: "https://api.smartmsi.lcl/app",
  },
  editorToolbar: [
    [{ header: [false, 3, 4, 5, 6] }],
    ["bold"],
    /*
        [
            { align: "" },
            { align: "center" },
            { align: "right" },
            { align: "justify" }
        ],

        ["blockquote"],*/
    [{ list: "ordered" }, { list: "bullet" }],
    [{ script: "super" }],
    ["clean"],
  ],
};

export default Object.assign({}, config, localConfig);
