























import {Component, Prop, Vue} from "vue-property-decorator";
import {CompanyScores} from "@/smartmsi";

@Component
export default class ScoreEvolution extends Vue {
  @Prop() scores!: CompanyScores;

  getScoreClass(type: "A1"|"A2"|"global", year: number) {
    const classes = ["text-center"];
    if (this.edgeScores[type].worst[1] === year) {
      classes.push("worst");
    }
    if (this.edgeScores[type].best[1] === year) {
      classes.push("best");
    }
    return classes.join(" ");
  }

  get edgeScores() {
    const edgeScores = {
      A1: {best: [0, ""], worst: [200, ""]},
      A2: {best: [0, ""], worst: [200, ""]},
      global: {best: [0, ""], worst: [200, ""]},
    };


    Object.keys(this.scores).forEach(year => {
      if (edgeScores['A1'].best[0] < this.scores[year]['A1']) {
        edgeScores['A1'].best = [this.scores[year]['A1'], year];
      }

      if (edgeScores['A1'].worst[0] > this.scores[year]['A1']) {
        edgeScores['A1'].worst = [this.scores[year]['A1'], year];
      }

      if (edgeScores['A2'].best[0] < this.scores[year]['A2']) {
        edgeScores['A2'].best = [this.scores[year]['A2'], year];
      }

      if (edgeScores['A2'].worst[0] > this.scores[year]['A2']) {
        edgeScores['A2'].worst = [this.scores[year]['A2'], year];
      }

      if (edgeScores['global'].best[0] < this.scores[year]['global']) {
        edgeScores['global'].best = [this.scores[year]['global'], year];
      }
      if (edgeScores['global'].worst[0] > this.scores[year]['global']) {
        edgeScores['global'].worst = [this.scores[year]['global'], year];
      }

    });

    return edgeScores;
  }

}
