// https://vuex.vuejs.org/en/mutations.html

import { GlobalState } from "@/smartmsi";
import { MutationTree } from "vuex";

export default {
  authRequest(state) {
    state.authStatus = "loading";
  },
  authSuccess(state, { token, user }) {
    state.authStatus = "success";
    state.token = token;
    state.user = user;
  },
  authError(state) {
    state.authStatus = "error";
  },
  logout(state) {
    state.authStatus = "";
    state.user = null;
    state.token = "";
  },
  sudoSuccess(state, { user }) {
    if (!state.impersonator) {
      console.log("mutating", state);
      state.impersonator = state.user;
      state.user = user;
    }
  },
  endSudoSuccess(state) {
    if (state.impersonator) {
      state.user = state.impersonator;
      state.impersonator = undefined;
    }
  },
  setTableList(state, tableList) {
    state.tableList = tableList;
  },
} as MutationTree<GlobalState>;
