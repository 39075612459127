











































import Vue from "vue";
import {Component, Prop } from "vue-property-decorator";
import {Company, Entity} from "@/smartmsi.d";
import formatter from "@/mixins/formatter";
import {fromExcelFormat} from "@/utils/excel-format";

import InstrumentChart from '@/components/charts/InstrumentChart.vue';
import ConclusionGraph from "@/components/charts/ConclusionGraph.vue";
import {dataAverage} from "@/utils";

@Component({components: {InstrumentChart, ConclusionGraph}, mixins: [formatter]})
export default class InstrumentCard extends Vue {
  @Prop() instrument!: Entity;
  @Prop() xmlChart!: string;
  @Prop() company!: Company;
  @Prop({default: 5}) nbYears!: number;

  format(value: number, format: string) {
    return fromExcelFormat(value, format);
  }

  average(data: Record<number, Record<string, number>>) {
    return dataAverage(data, 1);
  }

  get instrumentData() {
    const o = {};

    const years = Object.keys(this.instrument.data);
    const newYears = years.slice(-1 * this.nbYears);

    Object.keys(this.instrument.data).forEach(key => {
      if (key >= newYears[0]) {
        o[key] = this.instrument.data[key];
      }
    });

    return o;
  }

  get latestYear() {
    return Math.max(...Object.keys(this.instrument.data));
  }

  get conformity() {
    switch (this.instrument.ref_comp) {
      case '>':
        return parseFloat(this.instrument.data[this.latestYear].value) >= parseFloat(this.instrument.ref_val);
      case '<':
        return parseFloat(this.instrument.data[this.latestYear].value) <= parseFloat(this.instrument.ref_val);
    }
    return '???';
  }

}

