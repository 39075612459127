import Vue from "vue";
import Vuetify from "vuetify";

import "@fortawesome/fontawesome-free/css/all.css";
import "@fortawesome/fontawesome-free/css/brands.css";

export const options = {
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      dark: {
        primary: "#f69121",
      },
    },
  },
  icons: {
    /*icons: {
      code: {
        component: FontAwesomeIcon,
        props: {
          icon: ["fas", "code"],
        },
      },
      road: {
        component: FontAwesomeIcon,
        props: {
          icon: ["fas", "road"],
        },
      },
      vue: {
        component: FontAwesomeIcon,
        props: {
          icon: ["fab", "vuejs"],
        },
      },
    },*/
  },
};

Vue.use(Vuetify);
// @ts-ignore
export default new Vuetify(options);
