



























































































































































































































































































































































































































































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import formatter from "@/mixins/formatter";
import {MsiIndex} from "@/smartmsi";

@Component({mixins: [formatter]})
export default class World extends Vue {
  @Prop({required: true}) indices!: MsiIndex[];

  mounted() {
    console.log("in mounted");
    this.highlightIndices();
  }
  updated() {
    console.log("Loading map");
    this.highlightIndices();
  }

  @Watch("indices")
  highlightIndices() {
    this.indices.forEach(index => {
      const country = index.stockExchange.isin.substr(0, 2);
      const wm = this.$refs.wm as HTMLElement;
      const elements = wm.querySelectorAll('.worldMap .' + country);

      if (elements) {
        elements.forEach(element => {
          element.setAttribute('data-msi-sei', `id-${index.id}`);
          element.addEventListener('click', () => this.$emit('seiClick', {id: index.id}));
        });
      } else {
        console.log("country not found", country);
      }
    });
  }
}
