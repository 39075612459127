
















import {Component, Prop, Vue} from "vue-property-decorator";
import formatter from "@/mixins/formatter";
import {Company} from "@/smartmsi";
import {Chart} from "highcharts-vue";
import * as Highcharts from "highcharts";
import SolidGauge from "highcharts/modules/solid-gauge";
SolidGauge(Highcharts);

@Component({components: {Chart}, mixins: [formatter]})
export default class AnalysisExcerpt extends Vue {

  @Prop() company!: Company;

  solidity = undefined;
  performance = undefined;

  created() {
    const gaugeOptions = {
      chart: {
        type: 'solidgauge'
      },

      title: null,

      pane: {
        center: ['50%', '85%'],
        size: '140%',
        startAngle: -90,
        endAngle: 90,
        background: {
          backgroundColor:
              Highcharts.defaultOptions.legend.backgroundColor ||   '#EEE',
          innerRadius: '60%',
          outerRadius: '100%',
          shape: 'arc'
        }
      },

      exporting: {
        enabled: false
      },

      tooltip: {
        enabled: false
      },

      // the value axis
      yAxis: {
        stops: [
          [.1, '#DF5353'], // red
          [.5, '#DDDF0D'], // yellow
          [.9, '#55BF3B'], // green
        ],
        lineWidth: 0,
        tickWidth: 0,
        minorTickInterval: null,
        tickAmount: 2,
        title: {
          y: -70
        },
        labels: {
          y: 16
        }
      },

      plotOptions: {
        solidgauge: {
          dataLabels: {
            y: 5,
            borderWidth: 0,
            useHTML: true
          }
        }
      }
    };

    // The solidity gauge
    this.solidity = Highcharts.merge(gaugeOptions, {
      yAxis: {
        min: 0,
        max: 100,
        title: {
          text: 'Financial Solidity'
        }
      },

      credits: {
        enabled: false
      },

      series: [{
        name: 'Financial Solidity',
        data: [26.4],
        dataLabels: {
          format:
              '<div style="text-align:center">' +
              '<span style="font-size:25px">{y}</span>' +
              '<span style="font-size:12px;opacity:0.4">/100</span>' +
              '</div>'
        },
        tooltip: {
          valueSuffix: ' km/h'
        }
      }]

    });

    // The RPM gauge
    this.performance = Highcharts.merge(gaugeOptions, {
      yAxis: {
        min: 0,
        max: 100,
        title: {
          text: 'Financial Performance'
        }
      },

      series: [{
        name: 'Financial Performance',
        data: [89.7],
        dataLabels: {
          format:
              '<div style="text-align:center">' +
              '<span style="font-size:25px">{y:.1f}</span>' +
              '<span style="font-size:12px;opacity:0.4">' +
              '/ 100' +
              '</span>' +
              '</div>'
        }
      }]

    });
  }

}
