<template>
   <v-icon x-small>fa-chevron-{{ row._expand ? 'down' : 'right' }}</v-icon>
</template>

<script>
import { Vue, Component, Prop} from "vue-property-decorator";

@Component()
export default class Expanded extends Vue {
  @Prop() row;
}
</script>
