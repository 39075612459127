<template>
  <div style="overflow:auto">
    <table class="table" ref="table">
      <thead>
        <tr>
          <th></th>
          <th v-for="col in cols" :key="'head-' + col.name">{{col.name}}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, idx) in items" :key="'row-'+idx">
          <td>
            <v-icon v-if="isItemValid(item)" color="success" @click="serverValidateItem(item)">fa fa-check-circle</v-icon>
            <v-icon v-else color="error">fa fa-times-circle</v-icon>
          </td>
          <td 
            v-for="col in cols" :key="'cell-' + col.name" 
            :class="{err: !isColValid(item, col) }"
            :title="errorMessage(item, col)"
            >
            <template v-if="!col.if || item[col.if].value">
              <v-select
                v-if="col.choices"
                v-model="item[col.name].value" 
                @change="validateCol(item, col)"
                :items="$store.getters.codes(col.choices)" />
              <v-checkbox
                v-else-if="col.bool"
                v-model.number="item[col.name].value" 
                :false-value="0"
                :true-value="1"
                @change="validateItem(item)" />
              <v-text-field
                v-else
                v-model="item[col.name].value" 
                @input="validateCol(item, col)"
                />
            </template>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { Vue, Component, Prop } from "vue-property-decorator";
import { Load } from '@/services/xlsx';
import { validate } from 'vee-validate';


@Component()
export default class ImportEditor extends Vue {
  @Prop() file;

  items = [];
  cols = [];

  created() {
    const reader = new FileReader();

    reader.readAsArrayBuffer(this.file)
    reader.onload = () => {
      Load('company', reader.result).then(res => {
        this.cols = res.cols;
        this.items = res.items;
        this.validateAll();
      })
    }
  }

  validateAll() {
    this.items.forEach(this.validateItem);
  }

  validateItem(item) {
    this.cols.forEach(col => this.validateCol(item, col));
  }

  validateCol(item, col) {
    if (this.$store.state.rules.company[col.name] && (!col.if || item[col.if].value))
      validate(item[col.name].value, this.$store.state.rules.company[col.name]).then(v => this.$set(item[col.name], 'validation', v));
    else {
      this.$set(item[col.name], 'validation', { valid: true, errors:[] });
    }
  }

  isItemValid(item) {
    return Object.values(item).every(i => i.validation.valid);
  }

  isColValid(item, col) {
    return !item[col.name].validation || item[col.name].validation.valid;
  }

  errorMessage(item, col) {
    if (item[col.name].validation && item[col.name].validation.errors) 
      return item[col.name].validation.errors[0];
  }

  serverValidateItem(item) {
    const i = {};
    Object.keys(item).forEach(k => i[k] = item[k].value);
    this.$api.post('company/validate', i).then(j => {
      console.log(j.data);
    })
  }
}
</script>



<style lang="scss" scoped>

table {
  border-collapse: collapse;
}

th, td {
  border: 1px solid #aaa;
  padding: 3px;
  white-space: nowrap;
  width: 200px;

  ::v-deep [class*=error]:not(i) {
    background: var(--v-error-base);;
  }
  
  ::v-deep .v-text-field {
    padding-top: 0;
  }

  ::v-deep label,
  ::v-deep .v-input__slot::before,
  ::v-deep .v-text-field__details {
    display: none;
  }
}

td {
  color: #fff;

  &.err {
    background-color: #840000;
  }
}

</style>