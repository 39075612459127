







import {Component, Prop, Vue} from "vue-property-decorator";

@Component
export default class Debug extends Vue {
  @Prop({required: false, default: "Debug"}) title !: string;
}
