import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";

import vuetify from "@/plugins/vuetify";
import api from "@/services/api";
import i18n from '@/services/i18n';

import '@/services/validation';

// Plugins
import "./plugins/chartist";
import snack from "./plugins/snack";
import "./components";
import "./filters";
import cdnImage from "./mixins/cdn";

import "@/scss/nprogress.scss";
import "@/scss/app.scss";
import {registerPlugins} from "@/plugins";

registerPlugins(Vue);

Vue.mixin(cdnImage);

Vue.prototype.$api = api;
Vue.prototype.$snack = snack;
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App),
}).$mount("#app");
