import Vue from "vue";
import format from "date-fns/format";
import parseISO from "date-fns/parseISO";
import parse from "date-fns/parse";
import isValid from "date-fns/isValid";

const parseDate = (value: string) => {
  let parsed = parseISO(value);

  if (!isValid(parsed)) {
    parsed = parse(value, "t", new Date());
  }

  return parsed;
};

Vue.filter("date", (value: string) => {
  return format(parseDate(value), "dd.MM.yyyy");
});

Vue.filter("datetime", (value: string) => {
  return format(parseDate(value), "dd.MM.yyyy HH:mm");
});
