<template>
  <div>
    <v-autocomplete 
      :items="activities" 
      item-value="id" 
      item-text="name.fr"
      :filter="filterFunction"
      v-model="v"
      @input="$emit('input', v)"

      >
      <template v-slot:prepend>
        <v-icon @click="editDialog = true" small class="mt-1">fa fa-search</v-icon>
      </template>
    </v-autocomplete>
    <v-dialog v-model="editDialog" max-width="800"  scrollable>
      <v-card>
        <v-card-text>
        <h2>Domain</h2>
        <ul class="domains">
          <li :class="{selected: domain === d}" v-for="d in domains" :key="d" @click="domain = d">{{d}}</li>
        </ul>
        <h2>Sector</h2>
        <ul class="sectors" v-if="domain">
          <li :class="{selected: sector === s}" v-for="s in sectors" :key="s.name" @click="sector = s">{{s}}</li>
        </ul>
        <h2>Activity</h2>
        <ul class="activities">
          <li :class="{selected: a.id === value}" v-for="a in filteredActivities" :key="a.id" @click="setActivity(a)">{{a.name}}</li>
        </ul>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { Component, Prop, Vue, Watch } from "vue-property-decorator";

@Component()
export default class ActivitySelector extends Vue {

  @Prop() value;
  @Prop() error;

  editDialog = false;
  domain = null;
  sector = null;
  v = null;

  @Watch("value")
  onValueChanged() {
    this.updateValue();
  }

  created() {
    this.updateValue();
  }

  updateValue() {
    this.v = this.value;
    console.log("Got value", this.value, this.activities)
    if (this.value) {
      const a = this.activities.find(a => a.id === this.value);
      console.log("a", a);
      this.domain = a.sector.domain;
      this.sector = a.sector.name;
    }
  }

  activity(id) {
    return (this.activities.find(a => a.id === id) || {}).name
  }

  get activities() {
    return this.$store.state.activities;
  }

  get domains() {
    const domains = new Set();
    this.activities.forEach(a => domains.add(a.sector.domain));
    return Array.from(domains);
  }

  get sectors() {
    if (!this.domain) return [];
    const sectors = new Set();
    this.activities.forEach(a => { if (a.sector.domain === this.domain) sectors.add(a.sector.name); });
    return Array.from(sectors);
  }

  get filterFunction() {
    return (item, queryText, itemText) => {
      itemText = itemText.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
      queryText = queryText.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
      return itemText.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1;
    };
  }

  get filteredActivities() {
    if (!this.domain || !this.sector) return [];
    const activities = [];
    this.activities.forEach(a => { if (a.sector.domain === this.domain && a.sector.name === this.sector) activities.push(a); });
    return activities;
  }

  setActivity(a) {
    this.editDialog = false;
    this.$emit('input', a.id);
  }

}
</script>

<style scoped>
ul {
  list-style: none;
  padding: 0;
}
.selected {
  background: green;
}

</style>
