






















import * as Highcharts from "highcharts";
import HCData from "highcharts/modules/data";
import stockInit from 'highcharts/modules/stock'
import indicators from 'highcharts/indicators/indicators-all';
import Vue from "vue";
import {Component, Prop, Watch} from "vue-property-decorator";
import {Chart} from "highcharts-vue";

stockInit(Highcharts);
indicators(Highcharts);
HCData(Highcharts);

@Component({components: {Chart}})
export default class StockExchangeHistory extends Vue {
  @Prop() stockExchangeId!: number;
  @Prop() symbol!: number;

  overlays = [
    {code: 'abands', name: 'Acceleration Bands'},
    {code: 'bb', name: 'Bollinger Bands'},
    {code: 'dema', name: 'DEMA (Double Exponential Moving Average)'},
    {code: 'ema', name: 'EMA (Exponential Moving Average)'},
    {code: 'ikh', name: 'Ichimoku Kinko Hyo'},
    {code: 'keltnerchannels', name: 'Keltner Channels'},
    {code: 'linearRegression', name: 'Linear Regression'},
    {code: 'pivotpoints', name: 'Pivot Points'},
    {code: 'pc', name: 'Price Channel'},
    {code: 'priceenvelopes', name: 'Price Envelopes'},
    {code: 'psar', name: 'PSAR (Parabolic SAR)'},
    {code: 'sma', name: 'SMA (Simple Moving Average)'},
    {code: 'supertrend', name: 'Super Trend'},
    {code: 'tema', name: 'TEMA (Triple Exponential Moving Average)'},
    {code: 'vbp', name: 'VbP (Volume by Price)'},
    {code: 'vwap', name: 'WMA (Weighted Moving Average)'},
    {code: 'wma', name: 'VWAP (Volume Weighted Average Price)'},
    {code: 'zigzag', name: 'Zig Zag'},
  ];
  overlay = 'sma';

  oscillators = [
    {code: 'apo', name: 'Absolute price indicator'},
    {code: 'ad', name: 'A/D (Accumulation/Distribution)'},
    {code: 'aroon', name: 'Aroon'},
    {code: 'aroonoscillator', name: 'Aroon oscillator'},
    {code: 'atr', name: 'ATR (Average True Range)'},
    {code: 'ao', name: 'Awesome oscillator'},
    {code: 'cci', name: 'CCI (Commodity Channel Index)'},
    {code: 'chaikin', name: 'Chaikin'},
    {code: 'cmf', name: 'CMF (Chaikin Money Flow)'},
    {code: 'disparityindex', name: 'Disparity Index'},
    {code: 'cmo', name: 'CMO (Chande Momentum Oscillator)'},
    {code: 'dmi', name: 'DMI (Directional Movement Index)'},
    {code: 'dpo', name: 'Detrended price'},
    {code: 'linearRegressionAngle', name: 'Linear Regression Angle'},
    {code: 'linearRegressionIntercept', name: 'Linear Regression Intercept'},
    {code: 'linearRegressionSlope', name: 'Linear Regression Slope'},
    {code: 'klinger', name: 'Klinger Oscillator'},
    {code: 'macd', name: 'MACD (Moving Average Convergence Divergence)'},
    {code: 'mfi', name: 'MFI (Money Flow Index)'},
    {code: 'momentum', name: 'Momentum'},
    {code: 'natr', name: 'NATR (Normalized Average True Range)'},
    {code: 'obv', name: 'OBV (On-Balance Volume)'},
    {code: 'ppo', name: 'Percentage Price oscillator'},
    {code: 'roc', name: 'RoC (Rate of Change)'},
    {code: 'rsi', name: 'RSI (Relative Strength Index)'},
    {code: 'slowstochastic', name: 'Slow Stochastic'},
    {code: 'stochastic', name: 'Stochastic'},
    {code: 'trix', name: 'TRIX'},
    {code: 'williamsr', name: 'Williams %R'},
  ];
  oscillator = 'macd';

  loading = true;

  chartOptions = {};

  @Watch('overlay')
  onOverlayChanged(value: string) {
    const series = this.$refs.chart.chart.get('overlay');
    if (series) {
      series.remove(false);
      this.$refs.chart.chart.addSeries({
        type: value,
        linkedTo: 'company',
        id: 'overlay'
      });
    }
  }

  @Watch('oscillator')
  onOscillatorChanged(value: string) {
    const series = this.$refs.chart.chart.get('oscillator');

    if (series) {
      series.remove(false);
      this.$refs.chart.chart.addSeries({
        type: value,
        linkedTo: 'company',
        id: 'oscillator',

        yAxis: 2
      });
    }
  }

  created() {
    this.$api.get(`stock-exchange/${this.stockExchangeId}/history`).then(res => {
      const data = res.data;
      const volume = [];

      data.forEach(datum => {
        volume.push([datum[0], datum[5]]);
      });

      this.loading = false;
      this.chartOptions = {
        chart: {
          height: 600
        },
        title: {
          text: undefined,
        },
        legend: {
          enabled: true
        },
        credits: {
          text: '',
          enabled: false,
        },
        rangeSelector: {
          selected: 2
        },
        yAxis: [{
          height: '60%'
        }, {
          top: '60%',
          height: '20%'
        }, {
          top: '80%',
          height: '20%'
        }],
        plotOptions: {
          series: {
            showInLegend: true
          }
        },
        series: [{
          type: 'candlestick',
          id: 'company',
          name: this.symbol,
          data
        }, {
          type: 'column',
          id: 'volume',
          name: 'Volume',
          data: volume,
          yAxis: 1
        }, {
          type: 'pc',
          id: 'overlay',
          linkedTo: 'company',
          yAxis: 0
        }, {
          type: 'macd',
          id: 'oscillator',
          linkedTo: 'company',
          yAxis: 2
        }]
      };

    });
  }
}

