/**
 * Accessible from vue through this.$api
 */

import axios from "axios";

import store from "@/store";
import CONFIG from "@/config";
import { QueuedApiCall } from "@/smartmsi";

const YII_VALIDATION_ERROR = 422;
const YII_NOT_FOUND_ERROR = 404;
const YII_IGNORED_ERRORS = [YII_VALIDATION_ERROR, YII_NOT_FOUND_ERROR];

const API_STATUS_UNAUTHORIZED = 401;

const api = axios.create({ baseURL: CONFIG.api.url });

interface YiiModelError {
  field: string;
  message: string;
}

let isRefreshing = false;
let refreshSubscribers: QueuedApiCall[] = [];
const subscribeTokenRefresh = (cb: QueuedApiCall) =>
  refreshSubscribers.push(cb);

const onRefreshed = (token: string) => {
  console.log("refreshing ", refreshSubscribers.length, " subscribers");
  refreshSubscribers.map(cb => cb(token));
  refreshSubscribers = [];
};

api.interceptors.response.use(
  response => {
    const debug = response.headers['x-debug-link'];
    if (debug) {
      const url = response?.config?.url;
      console.info('yiiiDebug', url, CONFIG.api.url.replace(/\/\w+$/, '') + debug);
    }
    return response;
  },
  error => {
    const status = error.response ? error.response.status : false;
    const originalRequest = error.config;
    const debug = error.response.headers['x-debug-link'];
    if (debug) {
      const url = error.config?.url;
      console.info('yiiiDebug', url, CONFIG.api.url.replace(/\/\w+$/, '') + debug);
    }

    if (error.config.url === '/auth/refresh-token') {
      console.log('REDIRECT TO LOGIN');
      store.dispatch("logout").then(() => {
          isRefreshing = false;
      });
    }

    if (status === API_STATUS_UNAUTHORIZED) {


      if (!isRefreshing) {
        isRefreshing = true;
        console.log('dispatching refresh');
        store.dispatch("refreshToken").then(newToken => {
          isRefreshing = false;
          onRefreshed(newToken);
        }).catch(() => {
          isRefreshing = false;
        });
      }

      return new Promise(resolve => {
        subscribeTokenRefresh(token => {
          // replace the expired token and retry
          originalRequest.headers["Authorization"] = "Bearer " + token;
          resolve(axios(originalRequest));
        });
      });
    }

    // ignore some errors
    if (!error.response) {
      store.dispatch("addError", error).then();
    } else if (!YII_IGNORED_ERRORS.includes(error.response.status)) {
      store.dispatch("addError", error.response).then();
      //} else if (YII_NOT_FOUND_ERROR === error.response.status) {
    } else if (YII_VALIDATION_ERROR === error.response.status) {
      // turn [{field:foo,message:bar},...] into {foo: [bar]}
      if (error.response.data.length && error.response.data[0].field) {
        const data: { [key: string]: string[] } = {};
        error.response.data.forEach(
            (f: YiiModelError) => (data[f.field] = [f.message])
        );
        error.response.data = data;
      }
    }
    console.error("in api", error);
    return Promise.reject(error);


  }
);

// authentication
api.interceptors.request.use(function(config) {
  config.headers.Authorization =
    "Bearer " + localStorage.getItem("access_token");
  return config;
});

export default api;
